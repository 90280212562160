
 function efectMove(parent, fade, start, end) {
    const pare = document.querySelector(parent);
    const textfade = document.querySelector(fade);

    pare.addEventListener('mouseover', function () {
        textfade.style.transform = start;
     });
     
     pare.addEventListener('mouseout', function () {
         textfade.style.transform = end;
      });
 }

 efectMove('.retos', '.textback', 'translate(-130px, 79.5px) rotate(-90deg)', 'translate(-77px, 79.5px) rotate(-90deg)');
 efectMove('.media', '.textback2', 'translate(-130px,319px) rotate(-90deg)', 'translate(-76px, 319px) rotate(-90deg)');
 efectMove('.rea', '.textback3', 'translate(362px,111px) rotate(90deg)', 'translate(307px,111px) rotate(90deg)');
 efectMove('.buena', '.textback4', 'translate(417px, 350px) rotate(90deg)', 'translate(367px, 350px) rotate(90deg)');
 efectMove('.vac', '.textback5', 'translate(0,548px)', 'translate(0px, 494px)');

 window.addEventListener('scroll', function() {
   const y = window.scrollY;
   const pedagogia1 = document.querySelector('.UniPeda .parte1');
   const imgpedagogia = document.querySelector('.imgpedagogia');
   const parrafospedagogia = document.querySelector('.parrafos');
   const botonpedagogia = document.querySelector('.boton-pedagogia');

   if(y >= 1800 && y <= 2850) {
      pedagogia1.style.animation = 'fadeLeft1 3s forwards';
      imgpedagogia.style.animation = 'fadeRight1 3s ease 0s 1 normal forwards';
      parrafospedagogia.style.animation = 'fadeRight1 3s ease .8s 1 normal forwards';
      botonpedagogia.style.animation = 'fadeRight1 3s ease 1.1s 1 normal forwards';
   } 
 });



